import React from 'react'
import styles from './style.module.scss'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

import FlagTeam from '@assets/images/placeholder/team-placeholder.svg'
import { BLUR_DATA_URL_BASE64, S3_PREFIX } from '@shared-submodule/sm-constants'
// import { badgeColorDecide } from '@shared-submodule/utils'
import useWindowSize from '@shared/hooks/windowSize'
import CustomLink from '../customLink'
import useStatus from '@shared-submodule/sm-hooks/useStatus'
import { ENUM_CRICKET_MATCH_PAGES } from '@shared-submodule/sm-enum'
import { convertDt24h } from '@shared-submodule/utils'

const MyImage = dynamic(() => import('@shared/components/myImage'))

function LiveMatchCard({ data }) {
  const [width] = useWindowSize()
  const handleClick = value => {
    const { sStatusStr } = useStatus(value)
    const rewriteURLs = {}
    value?.oMatchSeos?.oSeo?.forEach(data => {
      if (data?.sSlug) {
        rewriteURLs[ENUM_CRICKET_MATCH_PAGES[data.eSubType]] = `/${data?.sSlug}`
      }
    })
    rewriteURLs.home = `/${value?.oSeo?.sSlug}`
    if (sStatusStr === 'live') return rewriteURLs?.commentary || rewriteURLs?.home
    else if (sStatusStr === 'scheduled') return rewriteURLs?.home || `/${value?.oSeo?.sSlug}`
    else if (sStatusStr === 'completed' || sStatusStr === 'cancelled') return rewriteURLs?.['full-scorecard'] || rewriteURLs?.home
  }

  const badgeColorDecide = data => {
    switch (data) {
      case 'live':
        return 'danger'
      default:
        return ''
    }
  }
  const badgeColorDecideLow = data => {
    switch (data) {
      case 'completed':
        return 'success'
      default:
        return ''
    }
  }

  return (
    <div className={`${styles.liveMatchCardList} liveMatchCardList mb-3 mb-lg-4`}>
      {data.bScoreCard &&
        data?.aScore &&
        data.aScore?.map((item, index) => {
          const { sStatus, sStatusStr, sFormat } = useStatus(item)
          if (item?.oSeries) {
            return (
              <div
                key={index}
                className={`${styles.liveMatchCard} ${
                  sStatusStr === 'scheduled' && styles.upcoming
                } d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap position-relative ${
                  item?.idataegoryId === '623184baf5d229bacb01030e' && width < 992 ? 'd-none' : 'd-flex'
                }`}
                onClick={() => handleClick(item)}>
                <CustomLink href={handleClick(item)} passHref>
                  <a className={styles.scoreCardLink}></a>
                </CustomLink>
                <div className={`${styles.team} flex-shrink-0 text-start`}>
                  <div className={`${styles.name} d-flex align-items-center flex-column flex-md-row`}>
                    <div className={`${styles.teamImg} flex-shrink-0`}>
                      <MyImage
                        src={item?.oTeamScoreA?.oTeam?.oImg?.sUrl ? `${S3_PREFIX}${item?.oTeamScoreA?.oTeam?.oImg?.sUrl}` : FlagTeam}
                        alt={item?.oTeamScoreA?.oTeam?.sAbbr}
                        blurDataURL={BLUR_DATA_URL_BASE64}
                        placeholder="blur"
                        width="90"
                        height="90"
                        // layout="responsive"
                        // sizes="(max-width: 767px) 24px, (max-width: 991px) 24px, (max-width: 1190px) 200px, 24px"
                      />
                    </div>
                    <div className="d-flex flex-column mt-1 mt-md-0 align-items-center align-items-md-start">
                      <b>{item?.oTeamScoreA?.oTeam?.sAbbr}</b>
                      <b>{item?.oTeamScoreA?.sScoresFull || ''}</b>
                      {/* <p className={`${styles.score} font-semi text-center text-md-start`}>
                      {item?.nLatestInningNumber === 1 &&
                        (item?.oTeamScoreA?.sScoresFull ? (
                          <b className={'theme-text'}>{item?.oTeamScoreA?.sScoresFull}</b>
                        ) : (
                          <b><Trans i18nKey="common:YetToBat" /></b>
                        ))}
                      {item?.nLatestInningNumber > 1 &&
                        (item?.oTeamScoreA?.sScoresFull?.includes('*') ? (
                          <b className={'theme-text'}>{item?.oTeamScoreA?.sScoresFull}</b>
                        ) : (
                          <b>{item?.oTeamScoreA?.sScoresFull}</b>
                        ))}
                    </p> */}
                    </div>
                  </div>
                </div>
                <div className={`${styles.info} text-center`}>
                  <span className={`live mb-1 font-bold text-${badgeColorDecide(sStatusStr)}`}>
                    {sStatusStr === 'live' && <span>{sStatus}</span>}
                  </span>
                  <p>
                    {item?.sSubtitle}
                    {sFormat && ','} {sFormat}, {convertDt24h(item?.dStartDate)}
                  </p>
                  {/* {width >= 767 && <p className="medium-text">{item?.sStatusNote}</p>} */}
                  {width >= 767 && <p className={`medium-text text-${badgeColorDecideLow(sStatusStr)}`}>{item?.sStatusNote}</p>}
                </div>
                <div className={`${styles.team} flex-shrink-0 text-end`}>
                  <div className={`${styles.name} d-flex align-items-center justify-content-end flex-column-reverse flex-md-row`}>
                    <div className="d-flex flex-column mt-1 mt-md-0 align-items-center align-items-md-end">
                      <b>{item?.oTeamScoreB?.oTeam?.sAbbr}</b>
                      {/* <p className={`${styles.score} font-semi text-center`}>
                      {item?.nLatestInningNumber === 1 &&
                        (item?.oTeamScoreB?.sScoresFull ? (
                          <b className={'theme-text'}>{item?.oTeamScoreB?.sScoresFull}</b>
                        ) : (
                          <b><Trans i18nKey="common:YetToBat" /></b>
                        ))}
                      {item?.nLatestInningNumber > 1 &&
                        (item?.oTeamScoreB?.sScoresFull?.includes('*') ? (
                          <b className={'theme-text'}>{item?.oTeamScoreB?.sScoresFull}</b>
                        ) : (
                          <b>{item?.oTeamScoreB?.sScoresFull}</b>
                        ))}
                    </p> */}
                      <b>{item?.oTeamScoreB?.sScoresFull || ''}</b>
                    </div>
                    <div className={`${styles.teamImg} flex-shrink-0`}>
                      <MyImage
                        src={item?.oTeamScoreB?.oTeam?.oImg?.sUrl ? `${S3_PREFIX}${item?.oTeamScoreB?.oTeam?.oImg?.sUrl}` : FlagTeam}
                        alt={item?.oTeamScoreB?.oTeam?.sAbbr}
                        errorImage={FlagTeam}
                        blurDataURL={BLUR_DATA_URL_BASE64}
                        placeholder="blur"
                        width="90"
                        height="90"
                        // layout="responsive"
                        // sizes="(max-width: 767px) 24px, (max-width: 991px) 24px, (max-width: 1190px) 200px, 24px"
                      />
                    </div>
                  </div>
                </div>
                {width < 767 && <p className="medium-text mx-auto mt-2 text-center w-100 text-success">{item?.sStatusNote}</p>}
              </div>
            )
          } else {
            return null
          }
        })}
    </div>
  )
}

LiveMatchCard.propTypes = {
  data: PropTypes.object
}

export default LiveMatchCard
